var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.isLoggedIn)?_c('v-btn',{attrs:{"fixed":"","color":"primary","elevation":"2","fab":"","bottom":"","right":""},on:{"click":function($event){_vm.navDrawer = true}}},[_c('v-icon',[_vm._v("la-bars")])],1):_vm._e(),(_vm.$store.getters.isLoggedIn)?_c('v-navigation-drawer',{attrs:{"fixed":"","app":"","right":"","mini-variant":_vm.isCollapsed,"mini-variant-width":"90","light":"","width":"300"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"pa-0",staticStyle:{"position":"absolute"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.collapse()}}},[_c('v-icon',[_vm._v("la-bars")])],1)],1),_c('center',[_c('br'),_c('br'),(!_vm.isCollapsed)?_c('img',{attrs:{"src":require("@/assets/logo.png"),"height":"120px"}}):_vm._e(),(_vm.isCollapsed)?_c('img',{attrs:{"src":require("@/assets/logo.png"),"width":"70%"}}):_vm._e(),_c('br'),(!_vm.isCollapsed)?_c('v-chip',{attrs:{"outlined":"","color":"light"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("la-user-circle")]),_c('span',[_vm._v(" "+_vm._s(_vm.userInfo.username))])],1):_vm._e(),_c('br'),(!_vm.isCollapsed)?_c('br'):_vm._e()],1)]},proxy:true}],null,false,21919661),model:{value:(_vm.navDrawer),callback:function ($$v) {_vm.navDrawer=$$v},expression:"navDrawer"}},[_c('v-divider'),_c('br'),_c('v-list',{attrs:{"rounded":"","dense":!_vm.isCollapsed}},[_vm._l((_vm.items),function(item){return _c('v-list-item',{directives:[{name:"tippy",rawName:"v-tippy",value:({
    arrow: true,
    arrowType: 'round',
    placement: 'right',
    followCursor: true,
    animateFill: true,
    animation: 'scale',
  }),expression:"{\n    arrow: true,\n    arrowType: 'round',\n    placement: 'right',\n    followCursor: true,\n    animateFill: true,\n    animation: 'scale',\n  }"}],key:item.title,attrs:{"color":"primary","to":item.route,"content":item.title}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s(item.icon))]),(item.title == 'الاشعارات' && _vm.notifications.length > 0)?_c('v-icon',{attrs:{"color":"red"}},[_vm._v("la-exclamation-circle")]):_vm._e()],1),(!_vm.isCollapsed)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1):_vm._e()],1)}),_c('v-list-item',{directives:[{name:"tippy",rawName:"v-tippy",value:({
    arrow: true,
    arrowType: 'round',
    placement: 'right',
    followCursor: true,
    animateFill: true,
  }),expression:"{\n    arrow: true,\n    arrowType: 'round',\n    placement: 'right',\n    followCursor: true,\n    animateFill: true,\n  }"}],attrs:{"color":"red","content":"خروج"},on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("la-power-off")])],1),(!_vm.isCollapsed)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("تسجيل خروج")])],1):_vm._e()],1)],2)],1):_vm._e(),_c('v-main',[(_vm.$store.getters.isLoggedIn)?_c('router-view'):_c('LoginPage')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }